






























import Invoice from '@/models/Invoice.model';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import { namespace } from 'vuex-class';
import { InvoiceStoreActions } from '@/store/invoice.store';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const InvoiceStore = namespace('invoices');

@Component
export default class InvoiceListComponent extends mixins(BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ required: true })
  public invoices!: Invoice[];

  @Prop({ default: false })
  private isLoading!: boolean;

  @InvoiceStore.Action(InvoiceStoreActions.DOWNLOAD_INVOICE_BY_ID)
  private downloadInvoiceAction!: (payload: { invoicePrefix: string, invoice: Invoice }) => Promise<void>;

  private headers = [
    { text: this.$t('INVOICE.INVOICE_DATE'), align: 'start', sortable: true, value: 'endDate' },
    { text: this.$t('INVOICE.NUMBER_OF_CLICKS'), align: 'start', sortable: true, value: 'clicks' },
    { text: this.$t('INVOICE.INVOICE_AMOUNT'), align: 'start', sortable: true, value: 'total' },
    { text: this.$t('GENERAL.ACTIONS'), align: 'start', sortable: false, value: 'actions' },
  ];

  private async downloadInvoice(invoice: Invoice) {
    this.$notifyInfoSimplified('GENERAL.NOTIFICATIONS.INVOICE_DOWNLOAD_STARTED');
    try {
      await this.downloadInvoiceAction({ invoicePrefix: this.$t('INVOICE.INVOICE').toString(), invoice: invoice});
    } catch(e) {
      this.handleAxiosError(e, () => {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.INVOICE_DOWNLOAD_ERROR');
      });
    }
  }
}
